import React from 'react';
import styled, { css } from 'styled-components';

import { Review as ReviewType } from '@/modules/Home/Home.types';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function Review({ avatar, review }: ReviewType) {
  return (
    <Component>
      <Avatar
        src={avatar.url}
        src2x={avatar.url_2x}
        srcWebp={avatar.url_webp}
        srcWebp2x={avatar.url_webp_2x}
      />
      <ReviewText>{review}</ReviewText>
    </Component>
  );
}

export default Review;

const Component = styled.div`
  position: relative;
  margin: 0 8px 50px;
  flex: 1;
  width: 100%;
  max-width: 239px;
  min-width: 239px;
  box-sizing: border-box;
  padding: 50px 29px 30px;
  border-radius: 30px;
  border: solid 1px ${colors.grayLight2};
  background-color: ${colors.white} !important;

  ${media.custom(css`
    margin: 0 20px 90px;
  `)}

  ${media.mobile(css`
    margin-bottom: 0;

    &:not(:first-child) {
      margin: 90px 0 0 0;
    }
  `)}
`;

const Avatar = styled(Picture)`
  position: absolute !important;
  top: 0;
  left: 50%;
  padding: 3px;
  width: 80px;
  height: 80px;
  border: solid 1px ${colors.grayLight2};
  border-radius: 50%;
  background-color: ${colors.white};
  transform: translate(-50%, -50%);

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    border-radius: 50%;
    object-fit: contain;
  }
`;

const ReviewText = styled.p`
  font-size: 13px;
  line-height: 2;
`;
