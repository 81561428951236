import { css } from 'styled-components';

export const ScrollStyles = css`
  /* Track */

  &::-webkit-scrollbar-track {
    background: rgb(176 186 201 / 50%);
    border: 1px solid rgb(208 213 220 / 50%);
    border-radius: 29px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #919caf;
    border-radius: 29px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* firefox */

  scrollbar-width: thin;
`;
