import React from 'react';
import styled, { css } from 'styled-components';

import { AppStore } from '@/modules/Home/Home.types';
import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

interface AppCardProps extends AppStore {
  otherApps?: boolean;
}

function AppCard({ image, link, otherApps }: AppCardProps) {
  return (
    <Component otherApps={otherApps} to={link} target="_blank">
      <Image otherApps={otherApps} src={image.url} />
    </Component>
  );
}

export default AppCard;

const Component = styled(Link)<{ otherApps: AppCardProps['otherApps'] }>`
  &:not(:first-child) {
    margin-left: 15px;

    ${({ otherApps }) =>
      otherApps &&
      css`
        margin-left: 5px;
      `}

    ${media.mobile(css`
      margin-left: 10px;
    `)}
  }
`;

const Image = styled(Picture)<{ otherApps: AppCardProps['otherApps'] }>`
  flex: 1 1 151px;
  max-width: 151px;

  ${media.custom(css`
    flex: 1 1 156px;
    max-width: 156px;
  `)}

  ${media.mobile(css`
    flex: 1 1 96px;
    max-width: 96px;
  `)}

  ${({ otherApps }) =>
    otherApps &&
    css`
      flex: 1 1 75px;
      max-width: 75px;

      ${media.custom(css`
        flex: 1 1 75px;
        max-width: 75px;
      `)}

      ${media.mobile(css`
        flex: 1 1 75px;
        max-width: 75px;
      `)}
    `}

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;
