import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { colors } from '@/constants/theme';
import OtherApp from '@/modules/Home/components/OtherApps/components/OtherApp';
import { media } from '@/utils/mixin';

function OtherApps() {
  const { otherAppsTitle, otherApps } = useHomeData();

  return (
    <Component>
      <ContentContainer>
        <Title>{otherAppsTitle}</Title>
        <AppsList>
          {otherApps &&
            otherApps.map(({ name, logo, apps }, index) => (
              <OtherApp key={index} name={name} logo={logo} apps={apps} />
            ))}
        </AppsList>
      </ContentContainer>
    </Component>
  );
}

export default OtherApps;

const Component = styled.section`
  padding: 43px 0 50px;
  background: ${colors.graySecond};

  ${media.custom(css`
    padding: 57px 0 50px;
  `)}

  ${ContentContainer} {
    ${media.custom(css`
      padding: 0 40px;
    `)}
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 1px;
  text-align: center;

  ${media.mobile(css`
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 1.14px;
  `)}
`;

const AppsList = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 53px;

  ${media.custom(css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    max-width: 756px;
    margin: 53px auto 0;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
