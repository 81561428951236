import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { useHomeData } from '@/modules/Home/Home.hooks';
import Review from '@/modules/Home/components/Reviews/components/Review';
import { media } from '@/utils/mixin';

function Reviews() {
  const { reviewsTitle, reviews } = useHomeData();

  return (
    <Component>
      <ContentContainer>
        <Title>{reviewsTitle}</Title>
        <Cards>
          {reviews &&
            reviews.map(({ avatar, review }, index) => (
              <Review key={index} avatar={avatar} review={review} />
            ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default Reviews;

const Component = styled.section`
  padding: 57px 0 50px;
  background-color: ${colors.graySecond};
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 1px;
  text-align: center;

  ${media.mobile(css`
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 1.14px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 93px;

  ${media.mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`;
