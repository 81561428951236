import * as Yup from 'yup';

import { GetInTouchFormData } from './GetInTouchForm.types';

export const validationSchema: Yup.SchemaOf<GetInTouchFormData> =
  Yup.object().shape({
    email: Yup.string()
      .label('Email')
      .email()
      .required('Please complete this required field.'),
    message: Yup.string()
      .label('Message')
      .required('Please complete this required field.'),
  });
