import React from 'react';
import styled from 'styled-components';

import { Preloader } from '@tager/web-components';

import { useCurrentPage } from '@/hooks';

import Footer from './components/Footer';

type Props = {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
};

function Layout({ children, isPreloaderHidden }: Props) {
  const pageTemplate = useCurrentPage().template;

  return (
    <Container>
      <Preloader hidden={isPreloaderHidden} />

      <Main>{children}</Main>
      {pageTemplate !== null && <Footer />}
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export default Layout;
