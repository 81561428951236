import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ScrollStyles } from '@/components/styles';

import { TextAreaProps } from './TextArea.types';

function TextArea({ label, errorMessage, ...props }: TextAreaProps) {
  return (
    <Component>
      <Label>{label}</Label>
      <Input invalid={!!errorMessage} {...props} />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default TextArea;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 13px;
  margin-left: 20px;
`;

const Input = styled.textarea<{ invalid: boolean }>`
  display: block;
  width: 100%;
  height: 106px;
  box-sizing: border-box;
  padding: 16px 20px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 12px;
  border: solid 1px rgba(151, 151, 151, 0.5);
  background-color: ${colors.white}
  outline: none;
  resize: none;
  margin-top: 10px;
  transition: .3s;
  
  ${ScrollStyles}
  
  &:focus {
    border-color: ${colors.aqua};

    ${({ invalid }) =>
      invalid &&
      css`
        border-color: ${colors.redSecond};
      `}
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${colors.redSecond};
    `}
`;

const Error = styled.div`
  position: absolute;
  right: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: ${colors.white};
  background-color: ${colors.redSecond};
  bottom: calc(100% - 16px);
  border-radius: 4px;
  padding: 5px 10px;

  &:before {
    content: '';
    position: absolute;
    top: 110%;
    right: 14px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    margin-top: -8px;
    background-color: ${colors.redSecond};
  }
`;
