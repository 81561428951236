import React from 'react';
import styled, { css } from 'styled-components';

import { useHomeData } from '@/modules/Home/Home.hooks';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import AppCard from '@/modules/Home/components/AppCard';
import { colors } from '@/constants/theme';
import AwardCard from '@/modules/Home/components/Hero/components/AwardCard';
import { media } from '@/utils/mixin';

function Hero() {
  const { logoImage, logoText, heroTitle, heroApps, phoneImage, awards } =
    useHomeData();

  return (
    <Component>
      <Top>
        <ContentContainer>
          <TopContent>
            <Left>
              <Logo>
                <LogoImage
                  src={logoImage.url}
                  src2x={logoImage.url_2x}
                  srcWebp={logoImage.url_webp}
                  srcWebp2x={logoImage.url_webp_2x}
                />
                <LogoText>{logoText}</LogoText>
              </Logo>

              <Content>
                <Title dangerouslySetInnerHTML={{ __html: heroTitle ?? '' }} />

                <AppList>
                  {heroApps &&
                    heroApps.map(({ image, link }, index) => (
                      <AppCard key={index} image={image} link={link} />
                    ))}
                </AppList>
              </Content>
            </Left>
            <Right>
              <PhoneImage
                src={phoneImage.url}
                src2x={phoneImage.url_2x}
                srcWebp={phoneImage.url_webp}
                srcWebp2x={phoneImage.url_webp_2x}
              />
            </Right>
          </TopContent>
        </ContentContainer>
      </Top>
      <Bottom>
        <ContentContainer>
          <Cards>
            {awards &&
              awards.map(({ image, text }, index) => (
                <AwardCard key={index} image={image} text={text} />
              ))}
          </Cards>
        </ContentContainer>
      </Bottom>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  ${media.custom(css`
    position: relative;
  `)}
`;

const Top = styled.div`
  position: relative;
  padding: 40px 0 94px;
  background: linear-gradient(to bottom, ${colors.white}, ${colors.graySecond});

  ${media.custom(css`
    position: static;
    padding: 57px 0 51px;
    background: none;
  `)}

  ${media.mobile(css`
    padding: 50px 0 70px;
  `)}

  ${ContentContainer} {
    position: relative;

    ${media.custom(css`
      position: static;
    `)}
  }
`;

const TopContent = styled.div`
  display: flex;

  ${media.mobile(css`
    position: relative;
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  position: relative;
  flex: 1 1 520px;
  max-width: 520px;

  ${media.custom(css`
    max-width: none;
  `)}

  ${media.mobile(css`
    position: static;
    display: flex;
    align-items: center;
    flex: auto;
    flex-direction: column;
  `)}
`;

const Right = styled.div``;

const Logo = styled.div`
  display: flex;
  align-items: center;

  ${media.custom(css`
    position: absolute;
    top: 15px;
    right: -20px;
  `)}

  ${media.mobile(css`
    position: inherit;
    top: auto;
    right: auto;
  `)}
`;

const LogoImage = styled(Picture)`
  width: 40px;
  height: 40px;
`;

const LogoText = styled.span`
  font-weight: 800;
  font-size: 16px;
  line-height: 1.25;
  margin-left: 15px;
`;

const Content = styled.div`
  margin-top: 58px;

  ${media.custom(css`
    margin-top: 0;
  `)}

  ${media.mobile(css`
    margin-top: 25px;
  `)}
`;

const Title = styled.div`
  max-width: 520px;

  ${media.mobile(css`
    max-width: 212px;
    margin: 0 auto;
  `)}

  p {
    font-weight: bold;
    font-size: 48px;
    line-height: 1.46;
    letter-spacing: 1.37px;

    ${media.custom(css`
      font-size: 54px;
      line-height: normal;
      letter-spacing: 3.38px;
    `)}

    ${media.mobile(css`
      font-size: 24px;
      letter-spacing: 1.5px;
      text-align: center;
    `)}

    strong {
      font-weight: 700;
      padding: 5px;
      margin-right: -5px;
      background-color: ${colors.yellow64};

      ${media.custom(css`
        padding: 0;
      `)}

      ${media.mobile(css`
        padding: 0 3px;
      `)}
    }
  }
`;

const AppList = styled.div`
  display: flex;
  margin-top: 28px;

  ${media.mobile(css`
    margin-top: 0;
    position: absolute;
    bottom: -60px;
  `)}
`;

const PhoneImage = styled(Picture)`
  position: absolute !important;
  max-width: 320px;
  max-height: 440px;
  bottom: -94px;
  right: 107px;

  ${media.custom(css`
    bottom: 0;
    right: 5px;
    max-width: 350px;
    max-height: 500px;
  `)}

  ${media.mobile(css`
    position: inherit !important;
    margin: 0 auto;
    max-width: 212px;
    max-height: 291px;
  `)}
`;

const Bottom = styled.div`
  padding: 60px 0 50px;

  ${media.custom(css`
    background: linear-gradient(
      to bottom,
      ${colors.white},
      ${colors.graySecond}
    );
    padding: 0 0 42px;
  `)}

  ${media.mobile(css`
    padding: 52px 0 40px;
  `)}
`;

const Cards = styled.div`
  padding: 0 106px 0 78px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${media.custom(css`
    padding: 0;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    max-width: 348px;
  `)}

  ${media.mobile(css`
    max-width: none;
  `)}
`;
