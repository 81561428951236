import React from 'react';
import styled, { css } from 'styled-components';

import { OtherApp as OtherAppType } from '@/modules/Home/Home.types';
import AppCard from '@/modules/Home/components/AppCard';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function OtherApp({ name, logo, apps }: OtherAppType) {
  return (
    <Component>
      <Name>{name}</Name>
      <Logo
        src={logo.url}
        src2x={logo.url_2x}
        srcWebp={logo.url_webp}
        srcWebp2x={logo.url_webp_2x}
      />
      <AppsList>
        {apps &&
          apps.map(({ image, link }, index) => (
            <AppCard key={index} image={image} link={link} otherApps />
          ))}
      </AppsList>
    </Component>
  );
}

export default OtherApp;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
  max-width: 170px;
  width: 100%;

  ${media.custom(css`
    max-width: none;
    margin: 0;
  `)}
`;

const Name = styled.p`
  font-weight: 800;
  font-size: 16px;
  line-height: 1.5;
  max-width: 140px;
  text-align: center;
`;

const Logo = styled(Picture)`
  flex: 1 1 140px;
  max-width: 140px;
`;

const AppsList = styled.div`
  display: flex;
  margin-top: -10px;
`;
