import React from 'react';
import styled, { css } from 'styled-components';

import { Award } from '@/modules/Home/Home.types';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function AwardCard({ image, text }: Award) {
  return (
    <Component>
      <Image src={image.url} />
      <Text>{text}</Text>
    </Component>
  );
}

export default AwardCard;

const Component = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
`;

const Image = styled(Picture)`
  max-width: 70px;
  max-height: 70px;
`;

const Text = styled.p`
  font-weight: 800;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  max-width: 180px;
  margin-top: 18px;

  ${media.custom(css`
    font-size: 18px;
    max-width: 175px;
    margin-top: 14px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
  `)}
`;
