import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { useSettingItem } from '@/hooks';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import appstoreIcon from '@/assets/svg/footer/appstore.svg';
import { media } from '@/utils/mixin';
import googlePlayIcon from '@/assets/svg/footer/googleplay.svg';

function Footer() {
  const downloadImage = useSettingItem('FOOTER_DOWNLOAD_IMAGE');
  const downloadText = useSettingItem('FOOTER_DOWNLOAD_TEXT')?.split('\n');
  const phoneImage = useSettingItem('FOOTER_PHONE_IMAGE');
  const appstoreUrl = useSettingItem('FOOTER_APPSTORE_URL');
  const googlePlayUrl = useSettingItem('FOOTER_GOOGLE_PLAY_URL');
  const links = useSettingItem('FOOTER_LINKS');
  const copyright = useSettingItem('FOOTER_COPYRIGHT');

  return (
    <FooterContainer>
      <FooterTop>
        <ContentContainer>
          <Download to="#">
            <Logo src={downloadImage?.url} />
            <DownloadText>
              {downloadText &&
                downloadText.map((item, index) => (
                  <TextItem key={index}>{item}</TextItem>
                ))}
            </DownloadText>
          </Download>
        </ContentContainer>
      </FooterTop>

      <FooterBottom>
        <ContentContainer>
          <Apps>
            {appstoreUrl ? (
              <AppLink to={appstoreUrl} target="_blank">
                <AppImage src={appstoreIcon} />
              </AppLink>
            ) : null}
            {googlePlayUrl ? (
              <AppLink to={googlePlayUrl} target="_blank">
                <AppImage src={googlePlayIcon} />
              </AppLink>
            ) : null}
          </Apps>

          <NavLinks>
            {links &&
              links.map(({ text, link }, index) => (
                <NavLinkItem key={index}>
                  <NavLink to={link}>{text}</NavLink>
                </NavLinkItem>
              ))}
          </NavLinks>

          <Copyright>{copyright}</Copyright>

          <PhoneImage src={phoneImage?.url} />
        </ContentContainer>
      </FooterBottom>
    </FooterContainer>
  );
}

export default Footer;

const FooterContainer = styled.footer``;

const FooterTop = styled.div`
  padding: 80px 0 30px;

  ${media.custom(css`
    padding: 100px 0 25px;
  `)}

  ${media.mobile(css`
    padding: 97px 0 30px;
  `)}

  ${ContentContainer} {
    max-width: 756px;

    ${media.custom(css`
      padding: 0 40px;
    `)}
  }
`;

const Download = styled(Link)`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Logo = styled(Picture)`
  flex: 1 1 105px;
  max-width: 105px;

  ${media.mobile(css`
    flex: 1 1 74px;
    max-width: 74px;
    margin-top: 14px;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const DownloadText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;

  ${media.mobile(css`
    flex-direction: row;
    margin-left: 0;
  `)}
`;

const TextItem = styled.span`
  font-weight: bold;
  font-size: 20px;

  ${media.custom(css`
    font-size: 18px;
  `)}

  ${media.mobile(css`
    font-size: 16px;

    &:not(:first-child) {
      margin-left: 3px;
    }
  `)}
`;

const FooterBottom = styled.div`
  padding-top: 50px;
  background: ${colors.graySecond};

  ${media.custom(css`
    padding-top: 25px;
  `)}

  ${media.mobile(css`
    padding-top: 32px;
  `)}

  ${ContentContainer} {
    position: relative;
    max-width: 756px;
    padding-bottom: 18px;

    ${media.custom(css`
      padding: 0 40px 18px;
    `)}

    ${media.mobile(css`
      padding: 0 20px 25px;
    `)}
  }
`;

const Apps = styled.div`
  display: flex;

  ${media.mobile(css`
    align-items: center;
    justify-content: center;
  `)}
`;

const AppLink = styled(Link)`
  &:not(:first-child) {
    margin-left: 15px;

    ${media.custom(css`
      margin-left: 10px;
    `)}
  }
`;

const AppImage = styled(Picture)`
  flex: 1 1 151px;
  max-width: 151px;

  ${media.mobile(css`
    flex: 1 1 105px;
    max-width: 105px;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 336px;
  margin-top: 20px;

  ${media.custom(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 35px;
    max-width: none;
    align-items: center;
    justify-content: center;
  `)}
`;

const NavLinkItem = styled.li`
  &:not(:last-child) {
    margin-right: 15px;
  }
`;

const NavLink = styled(Link)`
  font-size: 11px;
  opacity: 0.6;
  text-decoration: underline;

  ${media.custom(css`
    opacity: 1;
  `)}

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  font-size: 11px;
  opacity: 0.6;
  margin-top: 18px;

  ${media.custom(css`
    opacity: 1;
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    text-align: center;
  `)}
`;

const PhoneImage = styled(Picture)`
  position: absolute !important;
  right: 0;
  bottom: 0;
  flex: 1 1 290px;
  max-width: 290px;

  ${media.custom(css`
    flex: 1 1 272px;
    max-width: 272px;
  `)}

  ${media.mobile(css`
    display: none !important;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;
