import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';

import ContentContainer from '@/components/ContentContainer';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { submitGetInTouchForm } from '@/services/requests';
import SuccessForm from '@/components/SuccessForm';

import { GetInTouchFormData } from './GetInTouchForm.types';
import { validationSchema } from './GetInTouchForm.helpers';
import TextArea from './components/TextArea';
import TextField from './components/TextField';

function GetInTouchForm() {
  const [isFormSend, setFormSend] = useState(false);
  const { formTitle, formButtonTitle } = useHomeData();

  const { values, errors, touched, handleChange, handleSubmit } =
    useFormik<GetInTouchFormData>({
      initialValues: {
        email: '',
        message: '',
      },
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          await submitGetInTouchForm(values);

          setFormSend(true);
        } catch (error) {
          console.log(error);
        }

        setSubmitting(false);
      },
    });
  return (
    <Component isFormSend={isFormSend}>
      <ContentContainer>
        {isFormSend ? (
          <SuccessForm />
        ) : (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Title>{formTitle}</Title>
            <FormContainer>
              <FieldWrapper>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="youremail@gmail.com"
                  value={values.email}
                  onChange={handleChange}
                  errorMessage={
                    errors.email && touched.email ? errors.email : undefined
                  }
                />
              </FieldWrapper>

              <FieldWrapper>
                <TextArea
                  id="message"
                  name="message"
                  label="Message"
                  placeholder="Give us a feedback..."
                  value={values.message}
                  onChange={handleChange}
                  errorMessage={
                    errors.message && touched.message
                      ? errors.message
                      : undefined
                  }
                />
              </FieldWrapper>

              <ButtonWrapper>
                <Submit type="submit">{formButtonTitle}</Submit>
              </ButtonWrapper>
            </FormContainer>
          </Form>
        )}
      </ContentContainer>
    </Component>
  );
}

export default GetInTouchForm;

const Component = styled.section<{ isFormSend: boolean }>`
  background-color: ${colors.graySecond};
  margin: -266px 0 295px 0;

  ${({ isFormSend }) =>
    isFormSend &&
    css`
      background-color: ${colors.white};
      margin: 0;
    `}

  ${media.mobile(css`
    margin: 0;
    padding-top: 50px;
  `)}

  ${ContentContainer} {
    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 1 630px;
  max-width: 630px;
  padding: 50px 70px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 10%);
  background-color: ${colors.white};
  border-radius: 24px;
  margin: 0 auto;
  transform: translateY(50%);

  ${media.mobile(css`
    padding: 54px 20px 50px;
    border-radius: 10px;
    flex: auto;
    max-width: none;
    transform: none;
  `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 1px;
  text-align: center;

  ${media.mobile(css`
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 1.14px;
  `)}
`;

const FormContainer = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const FieldWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Submit = styled.button`
  max-width: 200px;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 24px;
  text-transform: uppercase;
  transition: 0.3s;
  box-shadow: 0 5px 20px 0 rgb(255 210 71 / 50%);
  background-color: ${colors.yellow};

  &:hover {
    box-shadow: 0 5px 20px 0 rgb(255 210 71 / 50%);
    background-color: #ffc204;
  }
`;
