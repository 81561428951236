import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { useHomeData } from '@/modules/Home/Home.hooks';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function Capabilities() {
  const { capabilitiesPhoneImage, capabilities } = useHomeData();

  const capabilitiesItems = capabilities && capabilities.split('\n');

  return (
    <Component>
      <ContentContainer>
        <Content>
          <PhoneImage
            src={capabilitiesPhoneImage.url}
            src2x={capabilitiesPhoneImage.url_2x}
            srcWebp={capabilitiesPhoneImage.url_webp}
            srcWebp2x={capabilitiesPhoneImage.url_webp_2x}
          />
          <List>
            {capabilitiesItems &&
              capabilitiesItems.map((text, index) => (
                <ListItem key={index}>
                  <Number>{index + 1}</Number>
                  <Text>{text}</Text>
                </ListItem>
              ))}
          </List>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Capabilities;

const Component = styled.section`
  padding: 20px 0;
  background: linear-gradient(to bottom, ${colors.white}, ${colors.graySecond});

  ${media.custom(css`
    padding: 68px 0;
  `)}

  ${media.mobile(css`
    padding: 38px 0;
  `)}

  ${ContentContainer} {
    ${media.custom(css`
      padding: 0 30px;
    `)}

    ${media.mobile(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  ${media.custom(css`
    justify-content: center;
  `)}

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const PhoneImage = styled(Picture)`
  margin-left: 110px;
  flex: 1 1 370px;
  max-width: 370px;

  ${media.custom(css`
    flex: 1 1 360px;
    max-width: 360px;
    margin-left: 0;
  `)}

  ${media.mobile(css`
    flex: 1 1 175px;
    max-width: 175px;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const List = styled.div`
  margin-left: 40px;

  ${media.mobile(css`
    margin: 20px 0 0 0;
  `)}
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mobile(css`
    align-items: center;
    justify-content: center;
  `)}

  &:not(:first-child) {
    margin-top: 50px;
  }
`;

const Number = styled.span`
  font-weight: 800;
  font-size: 50px;
  line-height: 0.8;
  color: ${colors.yellow};

  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const Text = styled.p`
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2;
  margin-top: 14px;

  ${media.custom(css`
    font-size: 18px;
    line-height: 1.33;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 0.88;
    text-align: center;
    margin-top: 7px;
  `)}
`;
