import React from 'react';
import styled from 'styled-components';

import Hero from './components/Hero';
import Capabilities from './components/Capabilities';
import OtherApps from './components/OtherApps';
import Reviews from './components/Reviews';
import GetInTouchForm from './components/Form';

function Home() {
  return (
    <Component>
      <Hero />
      <Capabilities />
      <OtherApps />
      <Reviews />
      <GetInTouchForm />
    </Component>
  );
}

export default Home;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
