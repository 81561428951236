import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SuccessIcon } from '@/assets/svg/success.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function SuccessForm() {
  return (
    <Component>
      <SuccessIconWrapper>
        <SuccessIcon />
      </SuccessIconWrapper>
      <Title>The application has been successfully sent!</Title>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 630px;
  max-width: 630px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 10%);
  background-color: ${colors.white};
  border-radius: 24px;
  padding: 30px;
  margin: -70px auto 0;

  ${media.mobile(css`
    margin-top: -60px;
  `)}
`;

const SuccessIconWrapper = styled.div``;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 15px;
`;

export default SuccessForm;
