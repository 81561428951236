import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks/useCurrentPage';
import { colors } from '@/constants/theme';

function DefaultTemplate() {
  const page = useCurrentPage();

  return (
    <Container>
      <Title>{page?.title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: page?.body ?? '' }} />
    </Container>
  );
}

export default DefaultTemplate;

const Container = styled.div`
  font-family: 'Times New Roman', sans-serif;
  margin: 8px;
`;

const Title = styled.h1`
  font-size: 40px;
  line-height: 50px;
`;

const Content = styled.div`
  font-size: 18px;
  line-height: 28px;

  h2 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  p {
    margin-top: 20px;
  }

  ul,
  ol {
    list-style-type: disc;
    padding-left: 40px;
    margin: 18px 0;

    li {
      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    max-width: 700px;
    width: 100%;
    margin-top: 30px;

    td,
    th {
      border: 1px solid ${colors.black};
      padding: 5px 10px;
    }

    th {
      background: #eee;
    }
  }

  a {
    color: #0000ee;
    text-decoration: underline;
  }
`;
