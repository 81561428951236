import { request } from '@tager/web-core';

export function getExampleList() {
  return request.get({ path: '/tager/example' });
}

export function submitGetInTouchForm(params: {
  email: string;
  message: string;
}) {
  return request.post({ path: '/leads/submit', body: params });
}
